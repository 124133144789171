import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

function LeftTabsExample() {
  return (
    <Tab.Container  id="left-tabs-example" defaultActiveKey="first">
      <Row className="description-tab mb-5" >
        <Col sm={12}>
          <Nav variant="pills" className="flex-row">
            <Nav.Item >
              <Nav.Link eventKey="first" >Overview</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Reviews</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
               <h3>Kovea KB-0703W Alpine Pot Wide
PRODUCT DESCRIPTION
</h3>
<p>It is a super-fast heating pot that takes 2 minutes and 40 seconds to boil 500ml of water.


 </p>
 <p>It is convenient to use for various outdoor activities such as camping, mountaineering, fishing, and travel because it is easy to carry and use as it is a combined gas burner and camping pots
 </p>
 <h4>Features:</h4>
 <ul>
    <li>The heat exchange system technology exhibits higher thermal efficiency than general cookware.
    </li>
    <li>A container protection cover is constructed to mitigate the impact of the product.
    </li>
    <li>It has excellent corrosion resistance and abrasion resistance with hard anodizing coating.
    </li>
    <li>Material: stainless steel
    </li>
    <li>Weight: 535g
    </li>
    <li>Capacity: 1.5L
    </li>
    <li>Size : 130*130*218mm (Inner Box)
    </li>
    <li>Components: Lid, lid fixing ring, bowl stand, burner, container, container protection cover
    </li>
 </ul>

            </Tab.Pane>
            <Tab.Pane eventKey="second" style={{height:"300px"}}><h3>0 Reviews</h3></Tab.Pane>
          </Tab.Content>
        </Col>

        
      </Row>
    </Tab.Container>
  );
}

export default LeftTabsExample;