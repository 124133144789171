import React from 'react';
import Slider from 'react-slick';

const PromotionCarousel = () => {
  const settings = {
    vertical: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'ease-in-out',  // Smooth scrolling effect
  };

  return (
    <div className="col-xl-6 col-lg-5 col-sm-12 d-none d-lg-block">
      <div className="module sb-promotion sb-promotion--promotion">
        <div className="block-content clearfix">
          <Slider {...settings} className="sb-promotion--carousel">
            <div
              className="sb-brand--item slick-slide slick-cloned"
              data-slick-index={-1}
              aria-hidden="true"
              tabIndex={-1}
            >
              <a href="#" tabIndex={-1}>
                <i className="fa fa-phone" /> Free phone : {" "}
                <strong>+971-9890157</strong>
              </a>
            </div>
            <div
              className="sb-brand--item slick-slide slick-current slick-active"
              data-slick-index={0}
              aria-hidden="false"
              tabIndex={0}
            >
              <a href="#" tabIndex={0}>
                Free Shipping on all Orders. No Minimum Purchases Required*
              </a>
            </div>
            <div
              className="sb-brand--item slick-slide"
              data-slick-index={1}
              aria-hidden="true"
              tabIndex={-1}
            >
              <a href="#" tabIndex={-1}>
                Free 3 day delivery and free returns within the US
              </a>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default PromotionCarousel;
