import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Alldeails() {


    const settings1 = {
        dots: false,
        infinite: true,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1260,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 1
                }
            }
        ]
    };



    return (
        <div className='multi-products'>
            <div className="container" >
                <div className="home_section4">
                    <div className="module extraslider--home1 extraslider--banner_no extraslider--bestsell">
                        <h3 className="block-title">
                            <span>Top</span>  rated

                        </h3>
                        <div className="block-content row no-gutters">
                            <div className="sb-extraslider-container">
                                <Slider {...settings1} className="productCarousel products-list" >
                                    {/* Your slides for the first Slider */}
                                    <div className="productCarousel-slide product-layout product-list">
                                        {/* Product 1 */}
                                        <article className="product-item-container">
                                            <div className="left-block">
                                                <a
                                                    href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    className="product-item-photo"
                                                >
                                                    <img
                                                        className="card-image lazyautosizes lazyloaded"
                                                        data-sizes="auto"
                                                        src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/88/416/8__61473.1589247606.jpg?c=1"
                                                        data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/77/430/13__64950.1589249372.jpg?c=1"
                                                        alt="Dround round conse"
                                                        sizes="160px"
                                                    />
                                                </a>
                                            </div>
                                            <div className="right-block">
                                                <h4 className="card-title">
                                                    <a
                                                        href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    >
                                                        Dround round conse
                                                    </a>
                                                </h4>

                                                <div className="price-section price-section--withoutTax">
                                                    <span
                                                        data-product-price-without-tax=""
                                                        className="price price--withoutTax"
                                                        style={{ color: "blue" }}
                                                    >
                                                        AED 49.00
                                                    </span>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="productCarousel-slide product-layout product-list">
                                        {/* Product 2 */}
                                        <article className="product-item-container">
                                            <div className="left-block">
                                                <a
                                                    href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    className="product-item-photo"
                                                >
                                                    <img
                                                        className="card-image lazyautosizes lazyloaded"
                                                        data-sizes="auto"
                                                        src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/77/430/13__64950.1589249372.jpg?c=1"
                                                        data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/77/430/13__64950.1589249372.jpg?c=1"
                                                        alt="Dround round conse"
                                                        sizes="160px"
                                                    />
                                                </a>
                                            </div>
                                            <div className="right-block">
                                                <h4 className="card-title">
                                                    <a
                                                        href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    >
                                                        Dround round conse
                                                    </a>
                                                </h4>

                                                <div className="price-section price-section--withoutTax">
                                                    <span
                                                        data-product-price-without-tax=""
                                                        className="price price--withoutTax"
                                                        style={{ color: "blue" }}
                                                    >
                                                        AED 49.00
                                                    </span>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </Slider>
                            </div>
                        </div>

                    </div>

                    <div className="module extraslider--home1 extraslider--banner_no extraslider--bestsell">
                        <h3 className="block-title">
                            <span>Latest</span> Products
                        </h3>
                        <div className="block-content row no-gutters">
                            <div className="sb-extraslider-container">
                                <Slider {...settings1} className="productCarousel products-list" >
                                    {/* Your slides for the first Slider */}
                                    <div className="productCarousel-slide product-layout product-list">
                                        {/* Product 1 */}
                                        <article className="product-item-container">
                                            <div className="left-block">
                                                <a
                                                    href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    className="product-item-photo"
                                                >
                                                    <img
                                                        className="card-image lazyautosizes lazyloaded"
                                                        data-sizes="auto"
                                                        src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/88/416/8__61473.1589247606.jpg?c=1"
                                                        data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/77/430/13__64950.1589249372.jpg?c=1"
                                                        alt="Dround round conse"
                                                        sizes="160px"
                                                    />
                                                </a>
                                            </div>
                                            <div className="right-block">
                                                <h4 className="card-title">
                                                    <a
                                                        href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    >
                                                        Dround round conse
                                                    </a>
                                                </h4>

                                                <div className="price-section price-section--withoutTax">
                                                    <span
                                                        data-product-price-without-tax=""
                                                        className="price price--withoutTax"
                                                        style={{ color: "blue" }}
                                                    >
                                                        AED 49.00
                                                    </span>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="productCarousel-slide product-layout product-list">
                                        {/* Product 2 */}
                                        <article className="product-item-container">
                                            <div className="left-block">
                                                <a
                                                    href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    className="product-item-photo"
                                                >
                                                    <img
                                                        className="card-image lazyautosizes lazyloaded"
                                                        data-sizes="auto"
                                                        src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/77/430/13__64950.1589249372.jpg?c=1"
                                                        data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/77/430/13__64950.1589249372.jpg?c=1"
                                                        alt="Dround round conse"
                                                        sizes="160px"
                                                    />
                                                </a>
                                            </div>
                                            <div className="right-block">
                                                <h4 className="card-title">
                                                    <a
                                                        href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    >
                                                        Dround round conse
                                                    </a>
                                                </h4>

                                                <div className="price-section price-section--withoutTax">
                                                    <span
                                                        data-product-price-without-tax=""
                                                        className="price price--withoutTax"
                                                        style={{ color: "blue" }}
                                                    >
                                                        AED 49.00
                                                    </span>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </Slider>
                            </div>
                        </div>

                    </div>

                    <div className="module extraslider--home1 extraslider--banner_no extraslider--bestsell">
                        <h3 className="block-title">
                            <span>BEST</span> SELLERS

                        </h3>
                        <div className="block-content row no-gutters">
                            <div className="sb-extraslider-container">
                                <Slider {...settings1} className="productCarousel products-list" >
                                    {/* Your slides for the first Slider */}
                                    <div className="productCarousel-slide product-layout product-list">
                                        {/* Product 1 */}
                                        <article className="product-item-container">
                                            <div className="left-block">
                                                <a
                                                    href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    className="product-item-photo"
                                                >
                                                    <img
                                                        className="card-image lazyautosizes lazyloaded"
                                                        data-sizes="auto"
                                                        src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/88/416/8__61473.1589247606.jpg?c=1"
                                                        data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/77/430/13__64950.1589249372.jpg?c=1"
                                                        alt="Dround round conse"
                                                        sizes="160px"
                                                    />
                                                </a>
                                            </div>
                                            <div className="right-block">
                                                <h4 className="card-title">
                                                    <a
                                                        href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    >
                                                        Dround round conse
                                                    </a>
                                                </h4>

                                                <div className="price-section price-section--withoutTax">
                                                    <span
                                                        data-product-price-without-tax=""
                                                        className="price price--withoutTax"
                                                        style={{ color: "blue" }}
                                                    >
                                                        AED 49.00
                                                    </span>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="productCarousel-slide product-layout product-list">
                                        {/* Product 2 */}
                                        <article className="product-item-container">
                                            <div className="left-block">
                                                <a
                                                    href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    className="product-item-photo"
                                                >
                                                    <img
                                                        className="card-image lazyautosizes lazyloaded"
                                                        data-sizes="auto"
                                                        src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/77/430/13__64950.1589249372.jpg?c=1"
                                                        data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/products/77/430/13__64950.1589249372.jpg?c=1"
                                                        alt="Dround round conse"
                                                        sizes="160px"
                                                    />
                                                </a>
                                            </div>
                                            <div className="right-block">
                                                <h4 className="card-title">
                                                    <a
                                                        href="https://sb-autoparts.mybigcommerce.com/fog-linen-chambray-towel-beige-stripe/"
                                                    >
                                                        Dround round conse
                                                    </a>
                                                </h4>

                                                <div className="price-section price-section--withoutTax">
                                                    <span
                                                        data-product-price-without-tax=""
                                                        className="price price--withoutTax"
                                                        style={{ color: "blue" }}
                                                    >
                                                        AED 49.00
                                                    </span>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </Slider>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    );
}

export default Alldeails;
