import React from 'react';
import { Col, Container,Row } from 'react-bootstrap';
import Footer from '../Footer';

const BlogCard = () => {
  return (
    <>
    <Container fluid className=' mb-5' style={{padding:"0px 50px"}} >
      <Row>
        <h2 className='mt-5 mb-4 text-center'>Blogs
        </h2>
        <Col lg={4} className='blog-cards'>
    <div className="card">
      <div className="card-img-holder">
        <img
          src="https://sub24.sbagh.com/storage/app/public/images/blog/2024-01-04-65963274d06e9.png"
          alt="Blog image"
          className="card-img-top"
        />
      </div>
      <div className="card-body">
        <h3 className="blog-title">Blackhawk Technical</h3>
        <span className="blog-time">Monday Jan 20, 2020</span>
        <p className="card-text description">
        Inaugural Golden Brick Awards Honors Blackhawk's Partners, Contributors
        </p>
        <div className="options">
          <span className="read-full-blog">
            Read Full Blog
          </span>
        </div>
      </div>
    </div>
    </Col>
    <Col lg={4} className='blog-cards'>
    <div className="card">
      <div className="card-img-holder">
        <img
          src="https://sub24.sbagh.com/storage/app/public/images/blog/2024-01-04-659632217df98.png"
          alt="Blog image"
          className="card-img-top"
        />
      </div>
      <div className="card-body">
        <h3 className="blog-title">to Support Scholarship Program</h3>
        <span className="blog-time">Monday Jan 20, 2020</span>
        <p className="card-text description">
        Blackhawk Foundation Receives $25K Donation from PepsiCo        </p>
        <div className="options">
          <span className="read-full-blog">
            Read Full Blog
          </span>
        </div>
      </div>
    </div>
    </Col>
    <Col lg={4} className='blog-cards'>
    <div className="card">
      <div className="card-img-holder">
        <img
          src="https://sub24.sbagh.com/storage/app/public/images/blog/2024-01-04-659631d42c2cb.png"
          alt="Blog image"
          className="card-img-top"
        />
      </div>
      <div className="card-body">
        <h3 className="blog-title">Advancement Initiative</h3>
        <span className="blog-time">Monday Jan 20, 2020</span>
        <p className="card-text description">
        Blackhawk Industrial Maintenance Class is First to Graduate through State Workforce        </p>
        <div className="options">
          <span className="read-full-blog">
            Read Full Blog
          </span>
        </div>
      </div>
    </div>
    </Col>
      </Row>
    </Container>
    <Footer/>
    </>
  );
};

export default BlogCard;
