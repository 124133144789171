import React, { useContext, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ReactImageGallery from 'react-image-gallery';
import LeftTabsExample from './Inputbtn';
import FeaturedProducts from './Productdeatailsowlcar';
const images = [
  {
    original: "https://cdn11.bigcommerce.com/s-ia0gsb6qmu/images/stencil/1280x1280/products/116/567/1__05974.1594887506.jpg?c=1",
    thumbnail: "https://cdn11.bigcommerce.com/s-ia0gsb6qmu/images/stencil/100x100/products/116/567/1__05974.1594887506.jpg?c=1"
  },
  {
    original: "https://cdn11.bigcommerce.com/s-ia0gsb6qmu/images/stencil/1280x1280/products/116/564/2__66314.1594887506.jpg?c=1",
    thumbnail: "https://cdn11.bigcommerce.com/s-ia0gsb6qmu/images/stencil/100x100/products/116/564/2__66314.1594887506.jpg?c=1"
  },
  {
    original: "https://cdn11.bigcommerce.com/s-ia0gsb6qmu/images/stencil/1280x1280/products/116/568/3__78796.1594887506.jpg?c=1",
    thumbnail: "https://cdn11.bigcommerce.com/s-ia0gsb6qmu/images/stencil/100x100/products/116/568/3__78796.1594887506.jpg?c=1"
  },
  {
    original: "https://cdn11.bigcommerce.com/s-ia0gsb6qmu/images/stencil/1280x1280/products/116/565/4__18237.1594887506.jpg?c=1",
    thumbnail: "https://cdn11.bigcommerce.com/s-ia0gsb6qmu/images/stencil/100x100/products/116/565/4__18237.1594887506.jpg?c=1"
  },
  {
    original: "https://cdn11.bigcommerce.com/s-ia0gsb6qmu/images/stencil/100x100/products/116/566/5__80428.1594887506.jpg?c=1",
    thumbnail: "https://cdn11.bigcommerce.com/s-ia0gsb6qmu/images/stencil/100x100/products/116/566/5__80428.1594887506.jpg?c=1"
  }
];
function Productdetails() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    mobileFirst: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToScroll: 6,
          slidesToShow: 6
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 5
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 4
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2
        }
      }
    ]
  };

  return (
    <div>
      <Container fluid style={{ padding: "0px 60px" }}>
        <Row>
          <Col lg={6} className='mt-5 mb-5 image-gallaery-class'>
            <ReactImageGallery items={images} thumbnailPosition={'left'} />
          </Col>
          <Col lg={6} className='mt-5 mb-5 image-gallaery-class'>
            <h3 className='text-center mt-5'>Kovea KB-0703WU Alpine Pot Wide Up
            </h3>
            <div className='review-details mt-3 mb-3'>
              <img src='/Asest/Google_Rating_Star-01 1.png' width={'80px'} height={'20px'} /> |
              <p>0 Reviews</p> |
              <p>1 Orders</p> |
              <p>0 Wish Listed</p>

            </div>
            <div style={{ padding: "0px 90px" }}>
              <p>It is a super-fast heating pot that takes 2 minutes and 40 seconds to boil 500ml of water.</p>
            </div>

            <div style={{ display: "flex", position: "relative", left: "0%", padding: " 0px 75px 0px 90px" }} className='mt-4'>
              <h5 style={{ margin: "0px 10px 0px 0px", textTransform: "capitalize", display: "flex", alignItems: "center" }}>Quantity :
              </h5>&nbsp;
              <div
                className="d-flex justify-content-center align-items-center quantity-box border rounded border-base"
                style={{ color: "#e0b102", width: '88px' }}
              >
                <span className="input-group-btn">
                  <button
                    className="btn btn-number __p-10"
                    type="button"
                    data-type="minus"
                    data-field="quantity"
                    style={{ color: "#e0b102" }}
                  >
                    -
                  </button>
                </span>
                <input
                  type="text"
                  name="quantity"
                  className="form-control input-number text-center cart-qty-field __inline-29 border-0 "
                  placeholder={1}
                  defaultValue={1}
                  product-type="physical"
                  min={1}
                  max={44}
                />
                <span className="input-group-btn">
                  <button
                    className="btn btn-number __p-10"
                    type="button"
                    product-type="physical"
                    data-type="plus"
                    data-field="quantity"
                    style={{ color: "#e0b102" }}
                  >
                    +
                  </button>
                </span>
              </div>


            </div>
            <div className='mt-3' style={{ padding: "0px 90px" }}>
              <h5>AED  <span style={{ fontSize: "32px", fontWeight: "600", color: "#FDB819 " }}>400</span> </h5>
            </div>
          
            <div className='mt-4' style={{ padding: "0px 80px", display: "flex", justifyContent: "space-between" }}>
              <Button className='book-now-btn-1' >Book Now</Button>
              <Button className='book-now-btn-2'>Add To Cart</Button>
            </div>
            <h4 style={{ padding: "0px 90px" }} className='mt-4'> 4 GREAT REASONS TO BUY FROM US:</h4>
            <div className='mt-4' style={{ display: "flex", justifyContent: "space-between", padding: "0px 80px" }} >
              <img src='https://cdn11.bigcommerce.com/s-ia0gsb6qmu/content/site/banner/home1/reasons/reasons-1.png' />
              <img src='https://cdn11.bigcommerce.com/s-ia0gsb6qmu/content/site/banner/home1/reasons/reasons-2.png' />
              <img src='https://cdn11.bigcommerce.com/s-ia0gsb6qmu/content/site/banner/home1/reasons/reasons-3.png' />
              <img src='https://cdn11.bigcommerce.com/s-ia0gsb6qmu/content/site/banner/home1/reasons/reasons-4.png' />
            </div>
          </Col>
        </Row>
        <LeftTabsExample />
        <Row>
          <h6 style={{ fontSize: "20px", fontWeight: "700", padding: "0px" }}>RELATED<span style={{ color: "#FDB819" }}> PRODUCTS</span></h6>
          <hr class="styled-hr"></hr>
          <FeaturedProducts />
        </Row>
      </Container>
    </div>
  );
}

export default Productdetails;
