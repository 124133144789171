import React, { useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import AOS from 'aos';

function Branddeatils() {
  const categeries = [
    {
      "title": "Black Hawk",
      "imageUrl": "https://sub24.sbagh.com/storage/app/public/product/thumbnail/2024-01-29-65b771ca1c70a.webp",
      "alt": "Body Parts",
      "link": "/body-parts"
    },
    {
      "title": "Auto Accessories",
      "imageUrl": "https://sub24.sbagh.com/storage/app/public/product/thumbnail/2024-01-29-65b78f203164e.webp",
      "alt": "The Gold Bracelets",
      "link": "/performance-parts"
    },
    {
      "title": "Camping Equipment",
      "imageUrl": "https://sub24.sbagh.com/storage/app/public/product/thumbnail/2024-01-29-65b76f327088d.webp",
      "alt": "Lighting",
      "link": "/lighting"
    },
    {
      "title": "Off-Road",
      "imageUrl": "https://sub24.sbagh.com/storage/app/public/product/thumbnail/2024-01-29-65b770ad181b6.webp",
      "alt": "Performance parts",
      "link": "/performance-parts"
    },
    {
      "title": "Repair parts",
      "imageUrl": "https://sub24.sbagh.com/storage/app/public/product/thumbnail/2024-01-29-65b76fa411094.webp",
      "alt": "Repair parts",
      "link": "/repair-parts"
    },
    {
      "title": "Wheels And Tires",
      "imageUrl": "https://sub24.sbagh.com/storage/app/public/product/thumbnail/2024-01-29-65b772df1bc62.webp",
      "alt": "Wheels And Tires",
      "link": "/wheels-and-tires"
    }
  ]
  useEffect(() => {
    AOS.init();
  }, []);

  const location = useLocation()


  const pathnames = location.pathname
  console.log(pathnames)
  return (
    <div>



      <Container fluid className='mt-5' style={{ padding: "0px 50px" }}>
        <Row>
          <Col lg={3}>
            <div className='all-categories'>
              <h5><img src='/Asest/catgerioes/186300.png' width={'25px'} height={'25px'} style={{ objectFit: "cover" }} /> ALL CATEGORIES</h5>
              {categeries.map((item) => {
                return (

                  <p className={pathnames === item.link ? 'active-brand' : null} >{item.title}</p>
                )
              })}
            </div>

          </Col>
          <Col lg={9}>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className='brand-deatils-row-tabs'>
                <Col sm={12} style={{ display: "flex", justifyContent: "end" }}>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="first">2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">3</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">4</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
              <Nav.Link eventKey="four"><img src='https://cdn-icons-png.freepik.com/256/12146/12146351.png?ga=GA1.1.769605160.1678772043&semt=ais_hybrid' width={'12px'} /></Nav.Link>
            </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="four"><img src='https://cdn-icons-png.freepik.com/256/152/152525.png?ga=GA1.1.769605160.1678772043&semt=ais_hybrid' width={'12px'} /></Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first" data-aos="fade-right" data-aos-duration="1000">
                      <Row>
                        {categeries.map((item) => {
                          return (
                            <Col lg={6}>
                              <Card style={{ display: "grid", justifyContent: "center", padding: "10px 0px", marginBottom: "20px" }}>
                                <img src={item.imageUrl} style={{ width: "250px", height: "250px", objectFit: "cover" }} className='text-center' />
                                <div className='text-center'>
                                  <img src='/Asest/Google_Rating_Star-01 1.png' style={{ height: "25px" }} />
                                </div>

                                <h6 className='text-center mt-3' style={{ color: "#FDB819" }}>{item.title}</h6>
                                <h5 className='mt-2 text-center'>AED  <span style={{ color: "#FDB819", fontWeight: "700" }}> 500</span>  <span style={{ fontSize: "10px", textDecoration: 'line-through' }}>AED 650</span></h5>

                              </Card>
                            </Col>

                          )
                        })}

                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row >
                        {categeries.map((item) => {
                          return (
                            <Col lg={4} >
                              <Card style={{ display: "grid", justifyContent: "center", padding: "10px 0px", marginBottom: "20px" }}>
                                <img src={item.imageUrl} style={{ width: "150px", height: "150px", objectFit: "cover" }} className='text-center' />
                                <div className='text-center'>
                                  <img src='/Asest/Google_Rating_Star-01 1.png' style={{ height: "22px" }} />
                                </div>

                                <h6 className='text-center mt-3' style={{ color: "#FDB819" }}>{item.title}</h6>
                                <h5 className='mt-2 text-center'>AED  <span style={{ color: "#FDB819", fontWeight: "700" }}> 500</span>  <span style={{ fontSize: "10px", textDecoration: 'line-through' }}>AED 650</span></h5>

                              </Card>
                            </Col>

                          )
                        })}
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {categeries.map((item) => {
                          return (
                            <Col lg={3}>
                              <Card style={{ display: "grid", justifyContent: "center", padding: "10px 0px", marginBottom: "20px" }}>
                               <img src={item.imageUrl} style={{ width: "100px", height: "100px", objectFit: "cover" }} className='text-center' />
                                <div className='text-center'>
                                  <img src='/Asest/Google_Rating_Star-01 1.png' style={{ height: "18px" }} />

                                </div>

                                <h6 className='text-center mt-3' style={{ color: "#FDB819" }}>{item.title}</h6>

                                <h5 className='mt-2 text-center'>AED  <span style={{ color: "#FDB819", fontWeight: "700" }}> 500</span>  <span style={{ fontSize: "10px", textDecoration: 'line-through' }}>AED 650</span></h5>

                              </Card>
                            </Col>

                          )
                        })}

                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="four">
                      <Row>
                        {categeries.map((item) => {
                          return (
                            <Col lg={12}>
                              <Row>
                                <Col lg={3}>
                                  <Card style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "150px", marginBottom: "20px" }}>
                                    <img src={item.imageUrl} style={{ width: "100px ", height: '100px', objectFit: "cover" }} />
                                  </Card>
                                </Col>
                                <Col lg={9}>
                                  <h5 >{item.title}</h5>
                                  <img src='/Asest/Google_Rating_Star-01 1.png' width={'80px'} style={{ position: "relative", right: "1%" }} />
                                  <h5 className='mt-2'>AED  <span style={{ color: "#FDB819", fontWeight: "700" }}> 500</span>  <span style={{ fontSize: "10px", textDecoration: 'line-through' }}>AED 650</span></h5>
                                  <p>A seemingly simple dustpan with a few features to make life easier. The arch and length of the dustpan eases cleanup, the wood turned handle provides firm grip and the rubber liner along the edge of the scoop will retrieve small crumbs with a single...</p>

                                </Col>
                              </Row>

                            </Col>

                          )
                        })}

                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>


      </Container>


    </div>
  )
}

export default Branddeatils
