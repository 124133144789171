import React from 'react';
import PromotionCarousel from './Headerrecatslick';
import { Link } from 'react-router-dom';

function Navbar() {
  const settings = {
    dots: false,
    arrows: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div>
      <div className="pace pace-inactive">
        <div
          className="pace-progress"
          data-progress-text="100%"
          data-progress="99"
          style={{ transform: 'translate3d(100%, 0px, 0px)' }}
        >
          <div className="pace-progress-inner"></div>
        </div>
        <div className="pace-activity"></div>
      </div>
      <header className="header logo-content--left">
        <div className="header-top">
          <div className="container-fluid">
            <div className="header-top-inner">
              <div className="row">
                <PromotionCarousel />
                <div className="col-xl-6 col-lg-7 col-sm-12 text-right">
                  <nav className="navUser">
                    <ul className="navUser-section list-inline">
                      <li className="navUser-item list-inline-item">
                        <div className="header-bottom__search collapsed-block">
                          <h5 className="search-info-heading">
                            <span
                              className="expander btn btn-outline-secondary"
                              data-toggle="collapse"
                              data-target="#searchContent"
                            >
                              <i className="fa fa-search"></i>
                              Search
                            </span>
                          </h5>
                          <div
                            id="searchContent"
                            className="collapse sb-quickSearch search-info-content"
                            aria-hidden="true"
                            tabIndex="-1"
                          >
                            <form className="sb-searchpro" action="/search.php">
                              <fieldset className="form-fieldset">
                                <div className="input-group">
                                  <input
                                    className="form-control form-input"
                                    data-search-quick=""
                                    name="search_query"
                                    id="search_query"
                                    data-error-message="Search field cannot be empty."
                                    placeholder="Search the store"
                                    autoComplete="off"
                                  />
                                  <div className="input-group-append">
                                    <button
                                      className="btn btn-outline-secondary"
                                      id="btn-quickSearch"
                                      type="submit"
                                    >
                                      <i className="fa fa-search"></i>
                                    </button>
                                  </div>
                                </div>
                              </fieldset>
                            </form>
                            <div className="dropdown dropdown--quickSearch">
                              <section
                                className="quickSearchResults"
                                data-bind="html: results"
                              ></section>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="navUser-item list-inline-item">

                        <a
                          className="cart-button cart-button--wishlist"
                          style={{ textDecoration: 'none' }}
                        >
                          {/* <svg className="icon-heart-o" width="15" height="19">
                            <use xlinkHref="#icon-heart-o"></use>
                          </svg> */}
                          <i class="fa fa-heart " style={{width:"15px"}}></i>&nbsp;

                          Wishlist
                        </a>
                      </li>
                      <li className="navUser-item list-inline-item">
                        <a
                          className="navUser-action has-dropdown my-account"
                          href="#"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa fa-user"></i> My Account
                          <i className="icon" aria-hidden="true">
                            <svg>
                              <use xlinkHref="#icon-chevron-down"></use>
                            </svg>
                          </i>
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-hidden="true"
                          tabIndex="-1"
                        >
                          <li className="dropdown-menu-item">
                            <a
                              href="/checkout"
                              title="Click here to proceed to checkout"
                            >
                              Check out
                            </a>
                          </li>
                          <li className="dropdown-menu-item">
                            <a href="/login.php">Sign in</a>
                          </li>
                          <li className="dropdown-menu-item">
                            <a href="/login.php?action=create_account">
                              Register
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div
          className="header-center  sticky-top"
          data-sticky-header=""
          style={{ top: '0px' }}
        >
          <div className="container-fluid" style={{ padding: '0px 20px' }}>
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-2 col-md-3 col-8 logo-container">
                <a className="header-logo" href='/'>
                  <div className="header-logo-image-container">
                    <img
                      className="header-logo-image"
                      src="/Asest/2023-12-20-6582c62837bf3.webp"
                      alt="sb-autoparts"
                      style={{ height: '75px' }}
                      title="sb-autoparts"
                    />
                  </div>
                </a>
              </div>
              <div className="col-lg-9 col-md-2 col-4" style={{display:"flex",position:"relative"}}>
                <nav
                  className="navPages-horizontal navPages-container"
                  id="menu"
                >
                  <ul className="navPages-list list-inline">
                    <li className="navPages-item list-inline-item navPages-item-page">
                      <a className="navPages-action" href='/'>
                        {' '}
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    
                    <li className="navPages-item list-inline-item">
                      <Link className="navPages-action" to={'/offroad'}>
                      Off-Road
                      </Link>
                    </li>

                    <li className="navPages-item list-inline-item">
                      <a
                        className="navPages-action has-subMenu"
                        style={{paddingRight:"0px"}}

                      >
                        Auto Accessories{' '}
                        <span
                          className="has-subMenu"
                          data-toggle="collapse"
                          data-target="#autoAccessoriesSubMenu"
                        >
                          <i className="icon navPages-action-moreIcon">
                            <svg>
                              <use xlinkHref="#icon-chevron-right"></use>
                            </svg>
                          </i>
                        </span>
                      </a>
                      <div
                        className="collapse navPage-subMenu"
                        id="autoAccessoriesSubMenu"
                      >
                        <ul className="navPage-subMenu-list list-unstyled">
                          <li className="navPage-subMenu-item">
                            <a
                              className="navPage-subMenu-action navPages-action"
                            >
                              Electrical & Lights
                            </a>
                          </li>
                          <li className="navPage-subMenu-item">
                            <a
                              className="navPage-subMenu-action navPages-action"
                            >
                              Winch
                            </a>
                          </li>
                          <li className="navPage-subMenu-item">
                            <a
                              className="navPage-subMenu-action navPages-action"
                            >
                              Tires & Wheels
                            </a>
                          </li>
                          <li className="navPage-subMenu-item">
                            <a
                              className="navPage-subMenu-action navPages-action"
                            >
                              Storage
                            </a>
                          </li>
                          <li className="navPage-subMenu-item">
                            <a
                              className="navPage-subMenu-action navPages-action"
                            >
                              Recovery & Towing
                            </a>
                          </li>
                          <li className="navPage-subMenu-item">
                            <a
                              className="navPage-subMenu-action navPages-action"
                            >
                              Outdoor & Camping
                            </a>
                          </li>



                        </ul>


                      </div>
                    </li>

                    

                    <li className="navPages-item list-inline-item">
                      <Link className="navPages-action" to={'/camping'}>
                        Camping Equipment
                      </Link>
                    </li>

                    <li className="navPages-item list-inline-item navPages-item-page">
                      <Link className="navPages-action" to={'/about'}>
                        {' '}
                        About Us              </Link>
                    </li>

                    <li className="navPages-item list-inline-item navPages-item-page">
                      <Link className="navPages-action" to="/blog" >
                        {' '}
                        Blog
                      </Link>
                    </li>
                    <li className="navPages-item list-inline-item navPages-item-page">
                      <Link className="navPages-action" to={'/media'}>
                        {' '}
                        Media
                      </Link>
                    </li>


                    <li className="navPages-item list-inline-item navPages-item-page">
                    <Link className="navPages-action" to={'/contact'}>
                        {' '}
                        Contact                      </Link>
                    </li>
                  </ul>
                </nav>
                <a
                  className="mobileMenu-toggle"
                  data-toggle="collapse"
                  data-target="#menu"
                >
                  <span className="mobileMenu-toggleIcon">Toggle menu</span>
                </a>
              </div>
            </div>
          </div>
        </div>

      </header>
    </div>
  );
}

export default Navbar;
