import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { Container, Row, Col, Figure } from 'react-bootstrap';
import styled from 'styled-components';

const images = [
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "A tent in the snowy mountains" },
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "A river between the mountains" },
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "Rocky mountains" },
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "Trains in a deposit" },
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "Greeny mountains" },
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "Storm in the sea" },
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "Fog in a valley of trees" },
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "Flying like a bird of prey" },
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "A woman and a dog looking at a foggy valley" },
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "Sea waves" },
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "Rocky cliffs" },
  { src: "https://sub24.sbagh.com/storage/app/public/media-gallery/six.png", desc: "Mountains seen through a forest" }
];

const Title = styled.h1`
  text-align: center;
  margin: 20px 0;
  font-size: 2rem;
  font-weight: bold;
`;

const GalleryItem = styled(Figure)`
  cursor: pointer;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  margin-bottom: 20px;
`;

const GalleryImage = styled(Figure.Image)`
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
    transform: scale(1.15);
  }
`;

const GalleryCaption = styled(Figure.Caption)`
  display: none;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const LightboxExample = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleShowModal = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <Container fluid style={{padding:"0px 45px"}}>
      <Title className='mt-5 mb-5'>Image Gallery</Title>
      <Row>
        {images.map((image, index) => (
          <Col md={4} key={index}>
            <GalleryItem className="gallery__item" onClick={() => handleShowModal(index)}>
              <GalleryImage src={image.src} alt={image.desc} className="gallery__image" />
              <GalleryCaption className="gallery__image__caption">{image.desc}</GalleryCaption>
            </GalleryItem>
          </Col>
        ))}
      </Row>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </Container>
  );
};

export default LightboxExample;
