import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Brands() {
  const settings = {
    dots: false,
    infinite: true,
    mobileFirst: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToScroll: 6,
          slidesToShow: 6
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 5
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 4
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2
        }
      }
    ]
  };


  return (
    <div className="module sb-brand sb-brand--static_brand">
      <div className="container  brands-page">


        <div className="block-content clearfix">
          <Slider {...settings} className="sb-brand--carousel d-flex justify-content-between">
            <div className="sb-brand--item">
              <a href="#" title="brand1">
                <img
                  className="img-fluid lazyloaded"
                  src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/br1.jpg"
                  alt="brand1"
                />
              </a>
            </div>
            <div className="sb-brand--item">
              <a href="#" title="brand2">
                <img
                  className="img-fluid lazyloaded"
                  src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/br2.jpg"
                  alt="brand2"
                />
              </a>
            </div>
            <div className="sb-brand--item">
              <a href="#" title="brand3">
                <img
                  className="img-fluid lazyloaded"
                  src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/br3.jpg"
                  alt="brand3"
                />
              </a>
            </div>
            <div className="sb-brand--item">
              <a href="#" title="brand4">
                <img
                  className="img-fluid lazyloaded"
                  src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/br4.jpg"
                  alt="brand4"
                />
              </a>
            </div>
            <div className="sb-brand--item">
              <a href="#" title="brand5">
                <img
                  className="img-fluid lazyloaded"
                  src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/br5.jpg"
                  alt="brand5"
                />
              </a>
            </div>
            <div className="sb-brand--item">
              <a href="#" title="brand6">
                <img
                  className="img-fluid lazyloaded"
                  src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/br6.jpg"
                  alt="brand6"
                />
              </a>
            </div>
            <div className="sb-brand--item">
              <a href="#" title="brand7">
                <img
                  className="img-fluid lazyloaded"
                  src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/br3.jpg"
                  alt="brand7"
                />
              </a>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Brands;
