import React, { useState } from 'react';


const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [formStatus, setFormStatus] = useState({
    submitting: false,
    success: false,
    warning: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormStatus({ ...formStatus, submitting: true });

    // Add form submission logic here

    // Simulating successful submission
    setTimeout(() => {
      setFormStatus({ submitting: false, success: true, warning: '' });
    }, 2000);
  };

  return (
    <div className="content">
      <div className="container">

        <div className="row mt-5">
          <div className="col-md-6 mr-auto">
            <h2>Contact Us</h2>
            <p className="">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste quaerat autem corrupti asperiores accusantium et fuga! Facere excepturi, quo eos, nobis doloremque dolor labore expedita illum iusto, aut repellat fuga!
            </p>
            <ul className="list-unstyled pl-md-5 " style={{marginLeft:"0px"}}>
              <li className="d-flex text-black mb-4 mt-4">
                <span className="mr-3"><span className="icon-map"></span></span>
              <img src='https://cdn-icons-png.freepik.com/256/61/61942.png?ga=GA1.1.769605160.1678772043&semt=ais_hybrid' width={'20px'}
               /> &nbsp;Address, <br />
              </li>
              <li className="d-flex text-black mb-2 mt-3 mb-4">
                <span className=""><span className="icon-phone"></span></span>
                <img src='https://cdn-icons-png.freepik.com/256/1/1257.png?ga=GA1.1.769605160.1678772043&semt=ais_hybrid' width={'18px'} height={'12px'} />&nbsp;
                +971-9890157              </li>
              <li className="d-flex text-black mt-2">
                <span className="mr-3"><span className="icon-envelope-o"></span></span>
                <img src='https://cdn-icons-png.freepik.com/256/542/542689.png?ga=GA1.1.769605160.1678772043&semt=ais_hybrid' width={'20px'} />&nbsp;

                info@blackhowk.com
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <form className="mb-5" onSubmit={handleSubmit} id="contactForm" name="contactForm" noValidate>
              <div className="row">
                <div className="col-md-12 form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder='Name'
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row mt-4 mb-4">
                <div className="col-md-12 form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder='Email'
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 form-group">
                  <textarea
                    className="form-control"
                    placeholder='Message'
                    name="message"
                    id="message"
                    cols="30"
                    rows="7"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-4">
                  <input
                    type="submit"
                    value="Send Message"
                    className="rounded-0 py-2 px-4"
                    disabled={formStatus.submitting}
                  />
                  <span className="submitting"></span>
                </div>
              </div>
            </form>
            {formStatus.warning && <div id="form-message-warning mt-4">{formStatus.warning}</div>}
            {/* {formStatus.success && <div id="form-message-success">Your message was sent, thank you!</div>} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
