import React from 'react'

function Dealsday() {
  return (
    <div>
      <div className="module clearfix sb-deals ">
        <h3 className="block-title">
          <span>Deals</span> of the day
        </h3>
        <p className="sub-title">Get 45% Off Your Order Of AED 500 And More</p>
        <div className="block-content row">
          <div className="sb-extraslider-image col-md-6">
            <div className="banners">
              <div className="banner-figure">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/t7.jpg"
                    alt="banner"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="countdown--box ">
            <div
              className="defaultCountdown defaultCountdown-28"
              data-timer="11/23/2023"
              data-countdown-id={28}
              style={{ display: "none" }}
            >
              <div className="time-item time-day">
                <div className="num-time">00</div>
                <div className="name-time">Day </div>
              </div>
              <div className="time-item time-hour">
                <div className="num-time">00</div>
                <div className="name-time">Hour</div>
              </div>
              <div className="time-item time-min">
                <div className="num-time">00</div>
                <div className="name-time">Min </div>
              </div>
              <div className="time-item time-sec">
                <div className="num-time">00</div>
                <div className="name-time">Sec</div>
              </div>
            </div>
          </div>
          <div className="sb-extraslider-image col-md-6">
            <div className="banners">
              <div className="banner-figure">
                <a href="#">
                  <img
                    className="img-fluid"
                    src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/t8.jpg"
                    alt="banner"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Dealsday
