import React from 'react'

function Offroad() {
  return (
    <div>
      Offroad
    </div>
  )
}

export default Offroad
