import React, { useContext } from 'react'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
import { Store } from '../App';

function Featuredproducts() {


  // const FEATUREDPRODUCTS = [
  //   {
  //     id: 1, tabitem: "Body Parts",
  //     productscards: [

  //       { name: "Kovea KB-0703WU Alpine Pot Wide Up", image: "/Asest/feature-products/f1.png", price: "50",link:"item-1" },
  //       { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f2.png", price: "70" },
  //       { name: "Kovea KL-805 Firefly Lantern 40 LUX", image: "/Asest/feature-products/f3.png", price: "80" },
  //       { name: "Kovea KH-2006 Power Sense Heater", image: "/Asest/feature-products/f4.png", price: "100" },
  //       { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f5.png", price: "30" },
  //       { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f6.png", price: "10" },
  //       { name: "WIRELESS CONTROL SYSTEM", image: "/Asest/feature-products/f6.png", price: "30" },
  //       { name: "R & P 4.10 D30/181F WITH STD INSTAL KIT", image: "/Asest/feature-products/f7.png", price: "15" },
  //       { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f8.png", price: "40" },
  //       { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f9.png", price: "20" },
  //       { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f10.png", price: "20" }










  //     ]




  //   },
  //   { id: 2, tabitem: "Electronics" },
  //   { id: 3, tabitem: "Lighting" },
  //   { id: 4, tabitem: "Performance Parts" },
  //   { id: 5, tabitem: "Repair Parts" },


  // ]

  const settings = {
    dots: false,
    infinite: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    margin: 30,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
  };

  const FEATUREDPRODUCTS = useContext(Store)

  return (
    <div>
      <div className="container-fluid mt-5 " style={{ padding: "0px 50px" }}>
        <div className="module clearfix listingtabs2">
          <div className="sb-listing-tabs">
            <div className="ltabs-heading">
              <h3 className="block-title">
                <span>Featured</span> products
              </h3>
              <p className="sub-title">The best products of Autoparts</p>
              <h3 className="block-title hidden">
                <a
                  href="https://sb-autoparts.mybigcommerce.com/featured-products/"
                  title="Featured products"
                >
                  Featured products
                </a>
              </h3>



              <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                <Row className="product-feater-tabs">
                  <Col lg={12} style={{ padding: "0px 100px" }}>
                    <Nav variant="pills" className="flex-row">
                      {FEATUREDPRODUCTS.map((item) => {
                        return (

                          <Nav.Item >
                            <Nav.Link eventKey={item.id} >{item.tabitem}</Nav.Link>
                          </Nav.Item>


                        )
                      })}
                    </Nav>
                  </Col>
                  <Col lg={12} >
                    <Tab.Content>
                      {FEATUREDPRODUCTS.map((item) => {
                        return (
                          <Tab.Pane eventKey={item.id}>



                            <div className="ltabs-container d-flex">
                              <div className="ltabs-items-container ltabs-items-container--noimage">
                                <section
                                  role="tabpanel"
                                  aria-hidden="false"
                                  className="tab-content  is-active "
                                  id="tab-sblisting-33"
                                  data-items-sblisting="https://sb-autoparts.mybigcommerce.com/featured-products/body-parts/?sort=newest"
                                  data-urltemplate="sbthemes/module/sb_listing_tabs/style2/default_items"
                                >
                                  <div className="productsByCategoryTabs-products--carousel ">
                                    <section
                                      className="productCarousel products-list slick-initialized slick-slider"
                                      data-slick={{
                                        "dots": false,
                                        "infinite": false,
                                        "mobileFirst": true,
                                        "slidesToShow": 1,
                                        "slidesToScroll": 1,
                                        "arrows": true,
                                        "margin": 30,
                                        "responsive": [
                                          {
                                            "breakpoint": 1260,
                                            "settings": {
                                              "slidesToScroll": 1,
                                              "slidesToShow": 4
                                            }
                                          },
                                          {
                                            "breakpoint": 991,
                                            "settings": {
                                              "slidesToScroll": 1,
                                              "slidesToShow": 3
                                            }
                                          },
                                          {
                                            "breakpoint": 400,
                                            "settings": {
                                              "slidesToScroll": 1,
                                              "slidesToShow": 2

                                            }
                                          },
                                          {
                                            "breakpoint": 0,
                                            "settings": {
                                              "slidesToScroll": 1,
                                              "slidesToShow": 1
                                            }
                                          }
                                        ]
                                      }}
                                    >

                                      <div className="slick-list draggable">
                                        <div
                                          className="slick-track"
                                          style={{
                                            opacity: 1,
                                            width: 1800,
                                            transform: "translate3d(0px, 0px, 0px)"
                                          }}
                                        >

                                          {item.productscards && item.productscards.map((item) => {
                                            return (

                                              <div
                                                className="productCarousel-slide product-layout product-grid slick-slide slick-current slick-active mt-5"
                                                style={{ width: 300 }}
                                                data-slick-index={0}
                                                aria-hidden="false"
                                                tabIndex={0}
                                              >
                                                <article className="product-item-container ">
                                                  <div className="left-block d-flex align-items-center justify-content-center">
                                                    <div className="product-card__gallery product-card__left d-none d-sm-block">
                                                      <div
                                                        className="item-img thumb-active "
                                                        data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/181x181/products/114/447/14__45289.1589448947.jpg?c=1"
                                                      >
                                                        <img
                                                          className="card-image lazyautosizes lazyloaded"
                                                          data-sizes="auto"
                                                          width="41px"
                                                          height="41px"
                                                          src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/41x41/products/114/447/14__45289.1589448947.jpg?c=1"
                                                          data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/41x41/products/114/447/14__45289.1589448947.jpg?c=1"
                                                          alt="Smart Compatible with for iWatch Series 5"
                                                          title="Smart Compatible with for iWatch Series 5"
                                                          sizes="41px"
                                                        />
                                                      </div>
                                                      <div
                                                        className="item-img "
                                                        data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/181x181/products/114/450/15__60819.1589448947.jpg?c=1"
                                                      >
                                                        <img
                                                          className="card-image lazyautosizes lazyloaded"
                                                          data-sizes="auto"
                                                          width="41px"
                                                          height="41px"
                                                          src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/41x41/products/114/450/15__60819.1589448947.jpg?c=1"
                                                          data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/41x41/products/114/450/15__60819.1589448947.jpg?c=1"
                                                          alt="Smart Compatible with for iWatch Series 5"
                                                          title="Smart Compatible with for iWatch Series 5"
                                                          sizes="41px"
                                                        />
                                                      </div>
                                                      <div
                                                        className="item-img "
                                                        data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/181x181/products/114/448/16__49351.1589448947.jpg?c=1"
                                                      >
                                                        <img
                                                          className="card-image lazyautosizes lazyloaded"
                                                          data-sizes="auto"
                                                          width="41px"
                                                          height="41px"
                                                          src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/41x41/products/114/448/16__49351.1589448947.jpg?c=1"
                                                          data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/41x41/products/114/448/16__49351.1589448947.jpg?c=1"
                                                          alt="Smart Compatible with for iWatch Series 5"
                                                          title="Smart Compatible with for iWatch Series 5"
                                                          sizes="41px"
                                                        />
                                                      </div>
                                                    </div>
                                                    <a
                                                      href="https://sb-autoparts.mybigcommerce.com/smart-compatible-with-for-iwatch-series-5/"
                                                      className="product-item-photo"
                                                      tabIndex={0}
                                                    >
                                                      <img
                                                        className="img-responsive lazyautosizes lazyloaded"
                                                        data-sizes="auto"
                                                        src={item.image}
                                                        data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/181x181/products/114/447/14__45289.1589448947.jpg?c=1"
                                                        alt="Smart Compatible with for iWatch Series 5"
                                                        title="Smart Compatible with for iWatch Series 5"
                                                        sizes="181px"
                                                      />
                                                    </a>
                                                    <div className="button-group">
                                                      <div className="action-item button-quickview">
                                                        <a
                                                          href="#"
                                                          className="quickview btn-button d-none d-md-block"
                                                          data-animation="false"
                                                          data-product-id={114}
                                                          title="Quick view"
                                                          tabIndex={0}
                                                        >
                                                          {" "}
                                                          <i className="fa fa-search" />
                                                        </a>
                                                      </div>
                                                      <div className="action-item addToCart 7">
                                                        <a
                                                          href="https://sb-autoparts.mybigcommerce.com/cart.php?action=add&product_id=114"
                                                          data-addtocart=""
                                                          data-product-id={114}
                                                          data-wait-message="Add to Cart"
                                                          className="action-link  button--cart"
                                                          title="Add to Cart"
                                                          tabIndex={0}
                                                        >
                                                          <i className="fa fa-shopping-basket" />
                                                        </a>
                                                      </div>
                                                      <div className="action-item wishlist">
                                                        <form
                                                          className="form"
                                                          method="post"
                                                          action="/wishlist.php?action=add&product_id=114"
                                                          data-wishlist-add=""
                                                        >
                                                          <input
                                                            type="hidden"
                                                            name="variation_id"
                                                            defaultValue=""
                                                            tabIndex={0}
                                                          />
                                                          <input
                                                            className="button button--small card-figcaption-button"
                                                            type="hidden"
                                                            defaultValue="Add to Wish List"
                                                            tabIndex={0}
                                                          />
                                                          <button
                                                            className="action-link button--wishlist"
                                                            type="submit"
                                                            title="Add to Wish List"
                                                            tabIndex={0}
                                                          >
                                                            <i className="fa fa-heart" />
                                                          </button>
                                                        </form>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="right-block">
                                                    <h4 className="card-title">
                                                      <a>


                                                        <Link to={item.link}> {item.name}</Link>
                                                      </a>
                                                    </h4>

                                                    <div className="price-section price-section--withoutTax ">
                                                      <span
                                                        data-product-price-without-tax=""
                                                        className="price price--withoutTax"
                                                      >
                                                        AED <span style={{ color: "black", fontSize: "20px", fontWeight: "400" }}>{item.price}</span>
                                                      </span>
                                                    </div>

                                                  </div>
                                                </article>

                                              </div>

                                            )
                                          })}

                                        </div>
                                      </div>

                                    </section>
                                  </div>
                                </section>
                                <div class="tab_button">
                                  <a href="https://sb-autoparts.mybigcommerce.com/featured-products/body-parts/" class="button">
                                    View All Products
                                  </a>
                                </div>

                              </div>
                            </div>


                          </Tab.Pane>
                        )
                      })}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>

          </div>
        </div>
      </div>


    </div>
  )
}

export default Featuredproducts
