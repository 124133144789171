import React from 'react'
import { Container ,Row,Col} from 'react-bootstrap'
import Footer from '../Footer'

function AboutUs() {
  return (
    <div>
      <Container className='mb-5' fluid style={{padding:"0px 50px"}} >
        <Row>
          <h2 className='mt-5 mb-4 text-center'>About Our Company</h2>
        </Row>
        <Col lg={12}>
        <h3>About Us
        </h3>
        <p>Crafting the Future with Innovation and Excellence

</p>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis mauris vitae tellus ultrices finibus eget at sapien. Nam vel odio auctor, varius nisi non, venenatis orci. Fusce in vestibulum elit. Nulla facilisi. Vivamus auctor mi ut velit volutpat, vel accumsan nisl malesuada.

</p>
<p>Curabitur hendrerit, mi ac tincidunt bibendum, urna elit vestibulum libero, nec fringilla justo elit et quam. Aenean dapibus, libero et varius fringilla, justo mi efficitur nisl, ut fringilla nisl felis at dolor. Sed posuere vel sapien id malesuada.

</p>
<p>Crafting the Future with Innovation and Excellence

</p>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis mauris vitae tellus ultrices finibus eget at sapien. Nam vel odio auctor, varius nisi non, venenatis orci. Fusce in vestibulum elit. Nulla facilisi. Vivamus auctor mi ut velit volutpat, vel accumsan nisl malesuada.

</p>
<p>Curabitur hendrerit, mi ac tincidunt bibendum, urna elit vestibulum libero, nec fringilla justo elit et quam. Aenean dapibus, libero et varius fringilla, justo mi efficitur nisl, ut fringilla nisl felis at dolor. Sed posuere vel sapien id malesuada.

</p>
<p>Crafting the Future with Innovation and Excellence

</p>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis mauris vitae tellus ultrices finibus eget at sapien. Nam vel odio auctor, varius nisi non, venenatis orci. Fusce in vestibulum elit. Nulla facilisi. Vivamus auctor mi ut velit volutpat, vel accumsan nisl malesuada.

</p>
<p>Curabitur hendrerit, mi ac tincidunt bibendum, urna elit vestibulum libero, nec fringilla justo elit et quam. Aenean dapibus, libero et varius fringilla, justo mi efficitur nisl, ut fringilla nisl felis at dolor. Sed posuere vel sapien id malesuada.

</p>
        </Col>
      </Container>
      <Footer/>
    </div>
  )
}

export default AboutUs
