import React from 'react'

function Catagerios() {

const categeries = [
  {
    "title": "Black Hawk",
    "imageUrl": "/Asest/catgerioes/c2.png",
    "alt": "Body Parts",
    "link": "/body-parts"
  },
  {
    "title": "Auto Accessories",
    "imageUrl": "/Asest/catgerioes/c3.png",
    "alt": "The Gold Bracelets",
    "link": "/performance-parts"
  },
  {
    "title": "Camping Equipment",
    "imageUrl": "/Asest/catgerioes/c4.png",
    "alt": "Lighting",
    "link": "/lighting"
  },
  {
    "title": "Off-Road",
    "imageUrl": "https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/cate4.jpg",
    "alt": "Performance parts",
    "link": "/performance-parts"
  },
  {
    "title": "Repair parts",
    "imageUrl": "https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/cate5.jpg",
    "alt": "Repair parts",
    "link": "/repair-parts"
  },
  {
    "title": "Wheels And Tires",
    "imageUrl": "https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/cate6.jpg",
    "alt": "Wheels And Tires",
    "link": "/wheels-and-tires"
  }
]

  return (
    <>
    <h3 className='categeries-heading'>Catagerios</h3>

    <div className='catagerios-main-div'>
      <div className="container">
  <div className="module sb-banner sb-banner--feature_category  ">
    <div className="block-content clearfix d-flex flex-row">

      {categeries.map((item)=>{
        return(
          <div className="banners">
          <div className="banner-figure">
            <a href={item.link}>
              <img
                className="img-fluid"
                src={item.imageUrl}
                alt="Body Parts"
              />
            </a>
          </div>
          <div className="banner-figcaption">
            <div className="banner-figcaption__body">
              <h5 className="banner-figcaption__title">{item.title}</h5>
            </div>
          </div>
        </div>

        )
      })}
   
     
     
     
     
     
     
     
     
      {/* <div className="banners">
        <div className="banner-figure">
          <a href="/performance-parts">
            <img
              className="img-fluid"
              src="/Asest/catgerioes/c3.png"
              alt="The Gold Bracelets"
            />
          </a>
        </div>
        <div className="banner-figcaption">
          <div className="banner-figcaption__body">
            <h5 className="banner-figcaption__title">Auto Accessories</h5>
          </div>
        </div>
      </div>
      <div className="banners">
        <div className="banner-figure">
          <a href="/lighting">
            <img
              className="img-fluid"
              src="/Asest/catgerioes/c4.png"
              alt="Lighting"
            />
          </a>
        </div>
        <div className="banner-figcaption">
          <div className="banner-figcaption__body">
            <h5 className="banner-figcaption__title">Camping Equipment</h5>
          </div>
        </div>
      </div>
      <div className="banners">
        <div className="banner-figure">
          <a href="/performance-parts">
            <img
              className="img-fluid"
              src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/cate4.jpg"
              alt="Performance parts"
            />
          </a>
        </div>
        <div className="banner-figcaption">
          <div className="banner-figcaption__body">
            <h5 className="banner-figcaption__title">Off-Road</h5>
          </div>
        </div>
      </div>
      <div className="banners">
        <div className="banner-figure">
          <a href="/repair-parts">
            <img
              className="img-fluid"
              src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/cate5.jpg"
              alt="Repair parts"
            />
          </a>
        </div>
        <div className="banner-figcaption">
          <div className="banner-figcaption__body">
            <h5 className="banner-figcaption__title">Repair parts</h5>
          </div>
        </div>
      </div>
      <div className="banners">
        <div className="banner-figure">
          <a href="/wheels-and-tires">
            <img
              className="img-fluid"
              src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/cate6.jpg"
              alt="Wheels And Tires"
            />
          </a>
        </div>
        <div className="banner-figcaption">
          <div className="banner-figcaption__body">
            <h5 className="banner-figcaption__title">Wheels And Tires</h5>
          </div>
        </div>
      </div> */}
    </div>
  </div>
</div>

      
    </div>
    </>
  )
}

export default Catagerios
