import React from 'react'
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

function Footer() {
  return (
    <div>
      <footer className="footer " role="contentinfo">
        <section className="footer-top">
          <div className="container">
            <div className="module sb-banner sb-banner--services  ">
              <div className="block-content clearfix d-flex flex-row">
                <div className="banners">
                  <div className="banner-figure">
                    <i className="fa fa-truck" />
                  </div>
                  <div className="banner-figcaption">
                    <div className="banner-figcaption__body">
                      <h5 className="banner-figcaption__title">Free Shipping</h5>
                      <p className="banner-figcaption__text">
                        There are many passages of Lorem Ipsum
                      </p>
                    </div>
                  </div>
                </div>
                <div className="banners">
                  <div className="banner-figure">
                    <i className="fa fa-money" />
                  </div>
                  <div className="banner-figcaption">
                    <div className="banner-figcaption__body">
                      <h5 className="banner-figcaption__title">Money Guarantee</h5>
                      <p className="banner-figcaption__text">
                        There are many passages of Lorem Ipsum
                      </p>
                    </div>
                  </div>
                </div>
                <div className="banners">
                  <div className="banner-figure">
                    <i className="fa fa-user-circle-o" />
                  </div>
                  <div className="banner-figcaption">
                    <div className="banner-figcaption__body">
                      <h5 className="banner-figcaption__title">Online Support</h5>
                      <p className="banner-figcaption__text">
                        There are many passages of Lorem Ipsum
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-center ">
          <div className="footer-center__first">
            <div className="container">
              <div className="row" style={{display:"flex",justifyContent:'space-between'}}>
                <article
                  className="col-lg-2 col-sm-12 footer-info collapsed-block"
                  data-section-type="storeInfo"
                >
                  <h5 className="footer-info-heading" style={{ textTransform: "capitalize" }}>
                    About our store
                    <span className="expander">
                      <i className="fa fa-chevron-circle-down" />
                    </span>
                  </h5>
                  <div className="footer-info-list">
                    <address>
                      <i className="fa fa-home" />
                      Oman
                    </address>
                    <address>
                      <i className="fa fa-envelope" />
                      <a href="mailto:Autoparts@domain.com">info@blackhawk.com</a>
                    </address>
                    <address>
                      <i className="fa fa-phone" />   +971-9890157
                    </address>
                  </div>
                </article>
                <article
                  className="collapsed-block col-lg-2 col-sm-12"
                  data-section-type="footer-webPages"
                >
                  <div className="footer-block">
                    <h5 className="footer-info-heading" style={{ textTransform: "capitalize" }}>
                      Quick Links
                      <span className="expander">
                        <i className="fa fa-chevron-circle-down" />
                      </span>
                    </h5>

                    <ul className="footer-info-list">
                      <li className="list-item">
                        <a href="#"> Off-Road
                        </a>
                      </li>

                      <li className="list-item">
                        <a href="#">Auto Accessories
                        </a>
                      </li>
                      <li className="list-item">
                        <a href="#">Camping Equipment
                        </a>
                      </li>
                      <li className="list-item">
                        <a href="#"> Featured Products
                        </a>
                      </li>
                      <li className="list-item">
                        <a href="#">Latest Products
                        </a>
                      </li>
                      <li className="list-item">
                        <a href="#">Best Selling Products
                        </a>
                      </li>
                      <li className="list-item">
                        <a href="#">Top Rated Products
                        </a>
                      </li>

                    </ul>
                  </div>
                </article>
                <article
                  className="col collapsed-block col-lg-2 col-sm-12"
                  data-section-type="footer-webPages"
                >
                  <h5 className="footer-info-heading" style={{ textTransform: "capitalize" }}>
                    Policy          <span className="expander">
                      <i className="fa fa-chevron-circle-down" />
                    </span>
                  </h5>

                  <ul className="footer-info-list">
                    <li>
                      <a href='#'>
                        Profile Info

                      </a>
                    </li>


                    <li>
                      <a href='#'>
                        Refund Policy
                        {" "}
                      </a>
                    </li>
                    <li>
                      <a href='#' >Return Policy
                      </a>
                    </li>
                    <li>
                      <a href="/sitemap.php">Cancellation Policy
                      </a>
                    </li>

                    <li>
                      <a href="#">Tems &amp; conditions</a>
                    </li>
                    <li>
                      <a href="#">
                        Privacy Policy</a>
                    </li>
                    {/* <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Help Center</a></li> */}
                  </ul>
                </article>
                <article
                  className="col collapsed-block col-lg-2 col-sm-12"
                  data-section-type="footer-brands"
                >
                  <h5 className="footer-info-heading" style={{ textTransform: "capitalize" }}>
                    Social
                    <span className="expander">
                      <i className="fa fa-chevron-circle-down" />
                    </span>
                  </h5>
                  <ul className="footer-info-list" style={{ width: "100px", display: "flex", justifyContent: "space-between" }}>
                    <li>< FaFacebookSquare color='white' size={'20px'} /></li>
                    <li><FaInstagramSquare color='white' size={'20px'} /></li>
                    <li><FaSquareXTwitter color='white' size={'20px'} /></li>



                  </ul>
                </article>
                <article
                  className="col col-lg-2 col-sm-12 collapsed-block"
                  data-section-type="footer-instagram"
                >
                  <div className="module sb-banner sb-instagram--home2Instagram  ">
                    <h5 className="footer-info-heading" style={{ textTransform: "capitalize" }}>
                      {" "}
                      Instagram
                      <span className="expander">
                        <i className="fa fa-chevron-circle-down" />
                      </span>
                    </h5>
                    <div className="block-content">
                      <div className="banners ">
                        <div className="banner-figure">
                          <a
                            className="example1"
                            href="/content/site/banner/home2/in1-full.jpg"
                          >
                            <img
                              className="img-fluid"
                              src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home2/in1.jpg"
                              alt=" "
                            />
                          </a>
                        </div>
                      </div>
                      <div className="banners ">
                        <div className="banner-figure">
                          <a
                            className="example1"
                            href="/content/site/banner/home2/in2-full.jpg"
                          >
                            <img
                              className="img-fluid"
                              src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home2/in2.jpg"
                              alt=" "
                            />
                          </a>
                        </div>
                      </div>
                      <div className="banners ">
                        <div className="banner-figure">
                          <a
                            className="example1"
                            href="/content/site/banner/home2/in3-full.jpg"
                          >
                            <img
                              className="img-fluid"
                              src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home2/in3.jpg"
                              alt=" "
                            />
                          </a>
                        </div>
                      </div>
                      <div className="banners ">
                        <div className="banner-figure">
                          <a
                            className="example1"
                            href="/content/site/banner/home2/in4-full.jpg"
                          >
                            <img
                              className="img-fluid"
                              src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home2/in4.jpg"
                              alt=" "
                            />
                          </a>
                        </div>
                      </div>
                      <div className="banners ">
                        <div className="banner-figure">
                          <a
                            className="example1"
                            href="/content/site/banner/home2/in5-full.jpg"
                          >
                            <img
                              className="img-fluid"
                              src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home2/in5.jpg"
                              alt=" "
                            />
                          </a>
                        </div>
                      </div>
                      <div className="banners ">
                        <div className="banner-figure">
                          <a
                            className="example1"
                            href="/content/site/banner/home2/in6-full.jpg"
                          >
                            <img
                              className="img-fluid"
                              src="https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home2/in6.jpg"
                              alt=" "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  <div class="modules">
    <h5 class="footer-info-heading">sb_themes.home2Instagram.title<span class="expander" ><i class="fa fa-chevron-circle-down"></i></span></h5>
    <div class="footer-info-list">
  <div id="instafeed" class="sb-instagram-gallery row" data-clearing></div>
    </div>
</div>




   */}
                </article>
              </div>
            </div>
          </div>
          {/* <div className="footer-center__two">
      <div className="container">
        <div className="row">
          <div className="footer-newsletter d-flex  align-items-center col-xl-9 col-lg-8  col-sm-12 ">
            <h5 className="footer-info-heading">
              Sign up for our exclusive Newsletter
            </h5>
            <p className="hidden">
              Get the latest updates on new products and upcoming sales
            </p>
            <form className="form" action="/subscribe.php" method="post">
              <fieldset className="form-fieldset">
                <input type="hidden" name="action" defaultValue="subscribe" />
                <input type="hidden" name="nl_first_name" defaultValue="bc" />
                <input type="hidden" name="check" defaultValue={1} />
                <div className="form-field">
                  <label className="form-label is-srOnly" htmlFor="nl_email">
                    Email Address
                  </label>
                  <div className="input-group justify-content-md-center">
                    <input
                      className="form-input form-control"
                      id="nl_email"
                      name="nl_email"
                      type="email"
                      defaultValue=""
                      placeholder="Your email address"
                    />
                    <div className="input-group-btn">
                      <button
                        type="submit"
                        className="btn btn-primary  button--primary"
                        name="commit"
                        id="Subscribe"
                      >
                        <i className="fa fa-paper-plane" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <div className="footer-socials d-flex align-items-center justify-content-end col-xl-3 col-lg-4 col-sm-12"></div>
        </div>
      </div>
    </div> */}
        </section>
        <section className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="footer-copyright col-sm-7">
                <span className="powered-by">© 2024 blackhawk </span>
                <span className="powered-by">
                  Powered by{" "}

                </span>
              </div>
              <div className="col-sm-5">
                <div className="footer-payment-icons">
                  <img
                    className="img-responsive lazyloaded"
                    src="https://cdn11.bigcommerce.com/s-fue6nqchrc/stencil/eb780090-af86-0138-baf3-0242ac110007/e/7ba43c90-b546-0138-6cd4-0242ac110018/img/pay-1.png"
                    data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/stencil/eb780090-af86-0138-baf3-0242ac110007/e/7ba43c90-b546-0138-6cd4-0242ac110018/img/pay-1.png"
                    alt="loading"
                  />
                  <img
                    className="img-responsive lazyloaded"
                    src="https://cdn11.bigcommerce.com/s-fue6nqchrc/stencil/eb780090-af86-0138-baf3-0242ac110007/e/7ba43c90-b546-0138-6cd4-0242ac110018/img/pay-2.png"
                    data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/stencil/eb780090-af86-0138-baf3-0242ac110007/e/7ba43c90-b546-0138-6cd4-0242ac110018/img/pay-2.png"
                    alt="loading"
                  />
                  <img
                    className="img-responsive lazyloaded"
                    src="https://cdn11.bigcommerce.com/s-fue6nqchrc/stencil/eb780090-af86-0138-baf3-0242ac110007/e/7ba43c90-b546-0138-6cd4-0242ac110018/img/pay-3.png"
                    data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/stencil/eb780090-af86-0138-baf3-0242ac110007/e/7ba43c90-b546-0138-6cd4-0242ac110018/img/pay-3.png"
                    alt="loading"
                  />
                  <img
                    className="img-responsive lazyloaded"
                    src="https://cdn11.bigcommerce.com/s-fue6nqchrc/stencil/eb780090-af86-0138-baf3-0242ac110007/e/7ba43c90-b546-0138-6cd4-0242ac110018/img/pay-4.png"
                    data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/stencil/eb780090-af86-0138-baf3-0242ac110007/e/7ba43c90-b546-0138-6cd4-0242ac110018/img/pay-4.png"
                    alt="loading"
                  />
                  <img
                    className="img-responsive lazyloaded"
                    src="https://cdn11.bigcommerce.com/s-fue6nqchrc/stencil/eb780090-af86-0138-baf3-0242ac110007/e/7ba43c90-b546-0138-6cd4-0242ac110018/img/pay-5.png"
                    data-src="https://cdn11.bigcommerce.com/s-fue6nqchrc/stencil/eb780090-af86-0138-baf3-0242ac110007/e/7ba43c90-b546-0138-6cd4-0242ac110018/img/pay-5.png"
                    alt="loading"
                  />
                </div>
              </div>
              <div className="footer-geotrust-ssl-seal">
                <table title="Click to Verify - This site chose GeoTrust SSL for secure e-commerce and confidential communications.">
                  <tbody>
                    <tr>
                      <td>
                        <br />
                        <a href="http://www.geotrust.com/ssl/" target="_blank" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section className="sb-backtotop">
          <div className="container">
            <div className="back-fixed-panel">
              <div className="back-panel-shares" data-role="sharesArea"></div>
              <div className="back-panel-operation">
                <div className="back-panel-unit back-totop hidden-top">
                  <i className="fa fa-angle-up" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>


    </div>
  )
}

export default Footer
