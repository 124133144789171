import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import Footer from './Footer';

function Productdeatailsowlcar() {

  const productscards = [

    { name: "Kovea KB-0703WU Alpine Pot Wide Up", image: "/Asest/feature-products/f1.png", price: "50",link:"item-1" },
    { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f2.png", price: "70" },
    { name: "Kovea KL-805 Firefly Lantern 40 LUX", image: "/Asest/feature-products/f3.png", price: "80" },
    { name: "Kovea KH-2006 Power Sense Heater", image: "/Asest/feature-products/f4.png", price: "100" },
    { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f5.png", price: "30" },
    { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f6.png", price: "10" },
    { name: "WIRELESS CONTROL SYSTEM", image: "/Asest/feature-products/f6.png", price: "30" },
    { name: "R & P 4.10 D30/181F WITH STD INSTAL KIT", image: "/Asest/feature-products/f7.png", price: "15" },
    { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f8.png", price: "40" },
    { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f9.png", price: "20" },
    { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f10.png", price: "20" }
  ]
  
  return (
    <div style={{padding:"0px"}}>

<OwlCarousel className='owl-theme mt-5 product-owl-carousel' items={4} loop margin={10} >
  {productscards && productscards.map((item)=>{
    return(
      <div className='item'>  
      <Card as={Link} to={item.link} style={{textDecoration:"none"}}>
      <img src={item.image} style={{padding:"20px"}} /> 
      <h6>{item.name}</h6>  
      <p>AED  <span style={{fontSize:"26px"}}>{item.price}</span></p>
      </Card>

  </div>
 

    )
  })}


</OwlCarousel>


    </div>
    
  )
}

export default Productdeatailsowlcar
