import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Featuredproducts from './Featuredproducts';
import Catageres from './Catagerios';
import BrandCarousel from './Brands';
import Productdetails from './Productdetails';
import Alldeails from './Alldeails';
import Dealsday from './Dealsday';
import Footer from './Footer';

const Slideshow = () => {
  const settings = {
    dots: true,
    arrows: true,
    mobileFirst: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const slides = [
    "https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/carousel/11/slideshow1-3.jpg?c=1",
    "https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/carousel/9/slideshow1-1.jpg?c=1",
    // "https://cdn11.bigcommerce.com/s-fue6nqchrc/images/stencil/original/carousel/10/slideshow1-2.jpg?c=1",
  ];

  return (
    <>
         
    <div className="section-slideshow">
      <div className="slideshow">
        <Slider {...settings} className="heroCarousel">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`heroCarousel-slide ${index === 2 ? 'slick-current slick-active' : ''}`}
              style={{
                backgroundImage: `url(${slide})`,
                width: 1270,
              }}
              aria-hidden={index !== 2}
              tabIndex={index === 2 ? 0 : -1}
              role="tabpanel"
              id={`slick-slide${10 + index}`}
              aria-describedby={`slick-slide-control${10 + index}`}
            >
              <a href="" tabIndex={index === 2 ? 0 : -1}>
                <img
                //   className="heroCarousel-image lazyloaded"
                  src={slide}
                  alt=""
                  title=""
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>


    </div>

    <Featuredproducts/>
    <Catageres/>

    <BrandCarousel/>
    <Dealsday/>
    <Alldeails/>
    <Footer/>

</>
  );
};

export default Slideshow;
