import React, { createContext, useContext, useEffect } from 'react'
import Navbar from './Components/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './Components/Homepage';
import Productdetails from './Components/Productdetails';
import Branddeatils from './Components/Branddeatils';
import Contact from './Components/Mainpages/Contact';
import Blog from './Components/Mainpages/Blog';
import AboutUs from './Components/Mainpages/AboutUs';
import Media from './Components/Mainpages/Media';
import CampingEquipment from './Components/Mainpages/CampingEquipment';
import Offroad from './Components/Mainpages/Offroad';

export const  Store = createContext();
function App() {


  const settings = {
    dots: false,
    arrows: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const carouselSettings = {
    dots: false,
    arrows: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 5000
  };

  const slickTrackStyle = {
    opacity: 1,
    height: '174px',
    transform: 'translate3d(0px, -74px, 0px)'
  };


  const categeries = [
    {
      "title": "Black Hawk",
      "imageUrl": "/Asest/catgerioes/c2.png",
      "alt": "Body Parts",
      "link": "/body-parts"
    },
    {
      "title": "Auto Accessories",
      "imageUrl": "/Asest/catgerioes/c3.png",
      "alt": "The Gold Bracelets",
      "link": "/performance-parts"
    },
    {
      "title": "Camping Equipment",
      "imageUrl": "/Asest/catgerioes/c4.png",
      "alt": "Lighting",
      "link": "/lighting"
    },
    {
      "title": "Off-Road",
      "imageUrl": "https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/cate4.jpg",
      "alt": "Performance parts",
      "link": "/performance-parts"
    },
    {
      "title": "Repair parts",
      "imageUrl": "https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/cate5.jpg",
      "alt": "Repair parts",
      "link": "/repair-parts"
    },
    {
      "title": "Wheels And Tires",
      "imageUrl": "https://cdn11.bigcommerce.com/s-fue6nqchrc/content/site/banner/home1/cate6.jpg",
      "alt": "Wheels And Tires",
      "link": "/wheels-and-tires"
    }
  ]
  const FEATUREDPRODUCTS = [
    {
      id: 1, tabitem: "Body Parts",
      productscards: [

        { name: "Kovea KB-0703WU Alpine Pot Wide Up", image: "/Asest/feature-products/f1.png", price: "50",link:"item-1" },
        { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f2.png", price: "70" },
        { name: "Kovea KL-805 Firefly Lantern 40 LUX", image: "/Asest/feature-products/f3.png", price: "80" },
        { name: "Kovea KH-2006 Power Sense Heater", image: "/Asest/feature-products/f4.png", price: "100" },
        { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f5.png", price: "30" },
        { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f6.png", price: "10" },
        { name: "WIRELESS CONTROL SYSTEM", image: "/Asest/feature-products/f6.png", price: "30" },
        { name: "R & P 4.10 D30/181F WITH STD INSTAL KIT", image: "/Asest/feature-products/f7.png", price: "15" },
        { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f8.png", price: "40" },
        { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f9.png", price: "20" },
        { name: "Kovea KH-0710 Fire Ball Gas Heater", image: "/Asest/feature-products/f10.png", price: "20" }
      ]




    },
    { id: 2, tabitem: "Electronics" },
    { id: 3, tabitem: "Lighting" },
    { id: 4, tabitem: "Performance Parts" },
    { id: 5, tabitem: "Repair Parts" },


  ]


  return (
    <Store.Provider  value={FEATUREDPRODUCTS}>


<BrowserRouter>
<Navbar/>
<Routes>
  <Route path='/' element={<Homepage/>} />

  {FEATUREDPRODUCTS.map((item)=>{
    return(
      item.productscards &&  item.productscards.map((item)=>{
        return(
          <Route path={item.link} element={<Productdetails/>}/>


        )
      })
    )
  })}
  {categeries.map((item)=>{
    return(
      <Route path={item.link} element={<Branddeatils/>}/>


    )
  })}
  <Route path='/blog' element={<Blog/>}/>
  <Route path='/about' element={<AboutUs/>}/>
  <Route path='/media' element={<Media/>}/>
  <Route path='/camping' element={<Branddeatils/>}/>
  <Route path='/offroad' element={<Productdetails/>}/>
  <Route path='/contact' element={<Contact/>}/>



</Routes> 
</BrowserRouter>







  
    </Store.Provider>
  )
}


export default App
